import { useEffect } from 'react';

export default function NoMatch(props) {

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.location.href = "/";
        }
    }, [])

    return;
}