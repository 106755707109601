import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../../../ThemeContext';

const Profil = ({ avatar, name, short }) => {
    const theme = useContext(ThemeContext);

    return (
        <div className="nav-profil">
            <img src={avatar} alt="" className="profil-avatar" />
            <div className="profil-name" style={{ color: theme.textColorSecondary }}>{name}
                <span className="profil-name-job" style={{ color: theme.textColorSecondary, opacity: 0.6 }}>{short}</span>
            </div>
        </div>
    );
}

Profil.propTypes = {
    avatar: PropTypes.string,
    name: PropTypes.string,
    short: PropTypes.string
}

export default Profil;