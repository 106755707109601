import React, { useState, useEffect, useMemo, useContext } from 'react';
import Portfolio from '../sections/portfolio/Portfolio';
import About from '../sections/about/About';
import Contact from '../sections/contact/Contact';
import ThemedNavBar from '../UI/navigation/ThemedNavBar';
import Footer from '../sections/footer/Footer';
import Header from '../sections/header/Header';
import { ThemeContext } from '../../ThemeContext';
import '../UI/navigation/_content.scss';
import Skills from '../sections/skills/Skills';

export default function Site(props) {
    const theme = useContext(ThemeContext);
    const [toggleMenu, setToggleMenu] = useState(false);

    const content = useMemo(() => {
        return <>
            <Header />
            <main>
                <About />
                <Skills />
                <Portfolio />
                <Contact />
            </main>
            <Footer />
        </>
    }, []);

    useEffect(() => {
        const body = document.getElementsByTagName("body");
        body[0].style.backgroundColor = theme.bgColorBody
        body[0].style.color = theme.textColorPrimary
        document.querySelectorAll(".content a, .link-style").forEach((element) => {
            element.style.color = theme.linkColor;
        }
        );
        const handleResize = () => setToggleMenu(false);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [theme])

    return (<>
        <div className={`site ${toggleMenu === true ? "navbar-open" : ""}`} >
            <ThemedNavBar
                toggleMenu={toggleMenu}
                toggle={() => setToggleMenu(!toggleMenu)}
                close={() => setToggleMenu(false)} />
            <div className="content" onClick={() => setToggleMenu(false)}>
                {content}
            </div>
        </div>
    </>
    );
}