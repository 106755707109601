import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '../../../ThemeContext';
import { FaHome, FaUser, FaEnvelope, FaWrench } from "react-icons/fa"
import { HiOutlineViewGrid } from "react-icons/hi";
import LinkScroll from './LinkScroll';
import Profil from './Profil';
import Toggler from './Toggler';
import './_navbar.scss';
import avatar from '../../../assets/images/avatar.jpg';
import linkedin from '../../../assets/images/linkedin.png';
import github from '../../../assets/images/github.png';
import useOverflowControl from '../hooks/useOverflowControl';

const ThemedNavBar = ({ toggleMenu, toggle, close, ...props }) => {
    const theme = useContext(ThemeContext);

    useOverflowControl(toggleMenu);

    return (
        <>
            <Toggler action={toggle} isNavOpen={toggleMenu} />

            <div className="navbar" style={{ backgroundColor: theme.bgColorPrimary }}>
                <Profil name="Laurent Monjou" avatar={avatar} short="Développeur Web" />
                <ul>
                    <LinkScroll close={close} hash='home'><FaHome /> <div>Home</div></LinkScroll>
                    <LinkScroll close={close} hash='about'><FaUser /> <div>Présentation</div></LinkScroll>
                    <LinkScroll close={close} hash='competences'><FaWrench /> <div>Compétences</div></LinkScroll>
                    <LinkScroll close={close} hash='portfolio'><HiOutlineViewGrid /> <div>Portfolio</div></LinkScroll>
                    <LinkScroll close={close} hash='contact'><FaEnvelope /> <div>Contact</div></LinkScroll>
                </ul>
                <div className="text-center nav-img-social">
                    <a href="https://www.linkedin.com/in/laurent-monjou/" target="_blank" rel="noreferrer"><img src={linkedin} alt="Icon LinkedIn" /></a> <a href="https://github.com/saxgard13" target="_blank" rel="noreferrer"><img src={github} alt="Icon Github" /></a>
                </div>

            </div >
        </>
    );
}

ThemedNavBar.propTypes = {
    toggleMenu: PropTypes.bool,
    toggle: PropTypes.func,
    close: PropTypes.func
}

export default ThemedNavBar;