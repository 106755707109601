import ThemedProgressBarWithLabel from "../../UI/progressBar/ThemedProgressBarWithLabel";
import myprofil from "../../../assets/images/skillscloud-light-min.png";

export default function Skills(props) {
    return (
        <section id="competences">
            <div className="container">
                <div className="skills-flex-container">
                    <div className="skills-progress">
                        <h2 className="module-title">
                            Compétences
                            <span className="shadow-title">Compétences</span>
                        </h2>
                        <p className="skills-introduction">
                            Bien que spécialisé Back-end et plus
                            particulièrement PHP, mes expériences
                            professionnelles m'ont permis de développer des
                            compétences techniques variées et une polyvalence
                            certaine.
                        </p>
                        <h4>Compétences principales</h4>
                        <ThemedProgressBarWithLabel
                            containerColor="#bbb"
                            value={90}
                        >
                            PHP, MySQL/MariaDB, Symfony
                        </ThemedProgressBarWithLabel>
                        <ThemedProgressBarWithLabel
                            containerColor="#bbb"
                            value={85}
                        >
                            HTML5, CSS3, Sass, BootStrap
                        </ThemedProgressBarWithLabel>
                        <ThemedProgressBarWithLabel
                            containerColor="#bbb"
                            value={80}
                        >
                            Javascript, Typescript, Jquery, React
                        </ThemedProgressBarWithLabel>
                        <div>
                            <h4>Environnement de travail</h4>
                            <p className="text-center, skills-introduction">
                                Linux ou Windows, Git, Github, NPM, Composer,
                                Apache, Visual Studio Code, Trello, Notion,
                                Docker
                            </p>
                        </div>
                    </div>
                    <div className="skills-cloud">
                        <img src={myprofil} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}
