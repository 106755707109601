var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    accessibility: true,
    lazyLoad: 'ondemand',
}


export var settingsSlider = {
    ...settings,
    responsive: [
        {
            breakpoint: 2100,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                slidesPerRow: 2,
            }
        }
    ]
};

export var settingsForm = {
    ...settings,
    responsive: [
        {
            breakpoint: 2100,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 1800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 1180,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                slidesPerRow: 2
            }
        },
    ]
};