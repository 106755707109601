import ThemedProgressBar from "./ThemedProgressBar";

const ThemedProgressBarWithLabel = ({ value, max, containerColor, height, children }) => {
    return (
        <div className="progress-container">
            <span className="progress-label">{children}</span>
            <ThemedProgressBar
                value={value}
                max={max}
                containerColor={containerColor}
                height={height}>
            </ThemedProgressBar>
        </div>
    );
};

export default ThemedProgressBarWithLabel;