import portfolio from "../assets/images/portfolio-min.png";
import gameophage from "../assets/images/gameophage-min.png";
import noubliepasdecrire from "../assets/images/noubliepasdecrire-min.png";
import sitacados from "../assets/images/sitacados-min.png";
import soho from "../assets/images/soho-min.jpg";
import sohoExtranet from "../assets/images/soho-extranet-min.jpg";
import bpForum from "../assets/images/bp-forum-min.png";
import mahaboArt from "../assets/images/mahabo-art-min.png";

export const PROJECTS = {
    projet1: {
        img: portfolio,
        title: "Ce portfolio",
        url: "",
        resume: "Bibliothèque React, CSS/Sass",
        description:
            "Portfolio conçu pour ma recherche d'emploi et pour développer également mes compétences avec la bibliothèque React.",
    },
    projet2: {
        img: noubliepasdecrire,
        title: "N'oublie pas d'écrire",
        url: "https://www.noubliepasdecrire.com",
        resume: "PHP - Architecture MVC, CSS/Sass, Javascript, Bootstrap, MySQL",
        description:
            "Depuis 2015 : Plateforme web qui facilite la mise en relation pour des échanges épistolaires et propose également des articles dédiés à la correspondance et à l'écriture manuscrite en générale.",
    },
    projet3: {
        img: gameophage,
        title: "Gameophage",
        url: "https://www.gameophage.com",
        resume: "PHP - Architecture MVC, MySQL, CSS/Sass, Javascript",
        description:
            "Depuis 2016 : Portail consacré aux jeux vidéo free to play toutes plateformes. De l’actualité, des astuces et des tests.",
    },
    projet4: {
        img: sitacados,
        title: "Sitacados",
        url: "",
        resume: "PHP, Mysql, CSS, Javascript",
        description:
            "2006 - 2016 : Guide des jeux en ligne primés et gratuits. Le site à connu un gros succès pendant plusieurs années atteignant les 10 000 visteurs/jour.",
    },
    projet5: {
        img: soho,
        title: "Magasins Soho",
        url: "",
        resume: "PHP, Mysql, CSS, Javascript",
        description:
            "2003-2004 : Vitrine des magasins SOHO. Revues de presse, informations sur l'entreprise et espaces réservés aux consommateurs et aux franchisés.",
    },
    projet6: {
        img: sohoExtranet,
        title: "Soho (Extranet)",
        url: "",
        resume: "PHP, Mysql, CSS, Javascript",
        description:
            "2003-2004 : Accès au catalogue SOHO, gestion des revues de presse, annuaire des correspondants et espace d'échange entre les membres de la société.",
    },
    projet7: {
        img: mahaboArt,
        title: "MAHABO ART",
        url: "",
        resume: "PHP, Mysql, CSS",
        description:
            "Début 2000 : Galerie présentant les travaux d'une artiste peintre.",
    },
    projet8: {
        img: bpForum,
        title: "Forum pour BP Chemicals",
        url: "",
        resume: "ASP, VBScript, Access 2000, CSS",
        description:
            "2001-2002 : Projet de stage qui a consisté à développé avec le langage ASP un forum pour l'intranet de BP Chemicals de Lavera (13).",
    },
};
