import React, { useContext } from 'react';
import { ThemeContext } from '../../../ThemeContext';
import ProgressBar from './ProgressBar';

const ThemedProgressBar = ({ value, max, containerColor = "lightgrey", height }) => {

    const theme = useContext(ThemeContext);

    return (
        <ProgressBar value={value} max={max} containerColor={containerColor} progressColor={theme.bgColorQuaternary} height={height} />
    );
};

export default ThemedProgressBar;