import './_card.scss';

const CardLight = ({ item }) => {

    return <div className="card-light">
        <img className="card-light-img" src={item.img} alt={item.title} loading="lazy" />
        <div className="card-body-light">

            <h3 className="card-title">{item.title} </h3>
            {item.resume ? <p className='card-resume text-center'>{item.resume}</p> : ''}
            <hr className="centered-hr" />

            {item.description ? <p className="card-light-description">{item.description}</p> : ''}
        </div>
    </div >
}

export default CardLight;