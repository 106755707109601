import myprofil from "../../../assets/images/photo-portfolio.png";

export default function About(props) {
    return (
        <section id="about">
            <div className="container">
                <div className="about-flex-container">
                    <div className="about-profil">
                        <img src={myprofil} alt="" />
                    </div>
                    <div className="about-content">
                        <h2 className="module-title">
                            A propos de moi
                            <span className="shadow-title">Présentation</span>
                        </h2>

                        <p>
                            En début de carrière, j'ai collaboré avec de grandes
                            entreprises pour concevoir et gérer tout ou partie
                            de leurs applications web. Par la suite, j'ai eu
                            l'opportunité de m'épanouir en tant que webmaster
                            indépendant pendant de nombreuses années. Cependant,
                            je nourris le désir de réintégrer le milieu salarial
                            afin de me consacrer à de nouveaux projets innovants
                            et de relever de nouveaux défis, tout en travaillant
                            au sein d'une équipe motivante.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
