import './_portfolio.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from '../../UI/card/Card';
import CardLight from '../../UI/card/CardLight';
import { PROJECTS } from '../../../data/projetsData';
import { FORMATIONS } from '../../../data/formationsData';
import { settingsForm, settingsSlider } from '../../../config/carousel.config';


export default function Portfolio(props) {

    return (
        <section id="portfolio">
            <div className="container">
                <h2 className="module-title">
                    Mes travaux
                    <span className="shadow-title">Portfolio</span>
                </h2>

                <p className='portfolio-description'>Série de projets qui témoigne de mon expertise en conception web </p>
                <Slider {...settingsSlider} key="projects">
                    {Object.keys(PROJECTS).map((item, i) => (
                        <Card item={PROJECTS[item]} key={i} />
                    ))}
                </Slider>

                <p className='portfolio-description mt-20'>Projets réalisés dans le cadre de formations en 2022-2023</p>

                <div className="mb-20">
                    <Slider {...settingsForm} key="formations">
                        {Object.keys(FORMATIONS).map((item, i) => (
                            <CardLight
                                item={FORMATIONS[item]}
                                key={i}
                            />
                        ))}
                    </Slider>
                </div>

            </div>
        </section>
    );
}