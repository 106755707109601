import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";

export default function Contact(props) {
    const [formStatus, setFormStatus] = useState("wait");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const form = useRef();

    const sendEmail = (formData) => {
        // e.preventDefault();

        emailjs
            .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                form.current,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            )
            .then(
                (result) => {
                    setFormStatus("send");
                },
                (error) => {
                    setFormStatus("error");
                }
            );
    };

    return (
        <section id="contact">
            <div className="container">
                <h2 className="module-title">
                    Contact
                    <span className="shadow-title">Contact</span>
                </h2>

                {formStatus === "send" ? (
                    <div className="message-success text-center">
                        Votre message a été envoyé avec succès.
                    </div>
                ) : (
                    ""
                )}

                {formStatus === "error" ? (
                    <div className="message-error text-center">
                        Une erreur s'est produite. Le message n'a pas pu être
                        envoyé.
                    </div>
                ) : (
                    ""
                )}

                {formStatus === "send" ? (
                    ""
                ) : (
                    <form
                        ref={form}
                        onSubmit={handleSubmit(sendEmail)}
                        className="contact-form"
                    >
                        <label>Nom</label>
                        <input
                            type="text"
                            name="user_name"
                            {...register("user_name", { required: true })}
                        />
                        {errors.user_name && (
                            <div
                                style={{ color: "red" }}
                                className="invalid-feedback d-block"
                            >
                                Votre non est requis.
                            </div>
                        )}
                        <label>Email</label>
                        <input
                            type="email"
                            name="user_email"
                            {...register("user_email", { required: true })}
                        />
                        {errors.user_email && (
                            <div className="invalid-feedback d-block">
                                Votre adresse mail est requise.
                            </div>
                        )}
                        <label>Message</label>
                        <textarea
                            name="message"
                            {...register("message", { required: true })}
                        />
                        {errors.message && (
                            <div className="invalid-feedback d-block">
                                Veuillez saisir le contenu de votre message.
                            </div>
                        )}
                        <button type="submit">Envoyer le message</button>
                    </form>
                )}
            </div>
        </section>
    );
}
